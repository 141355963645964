import * as React from "react";
import Layout from "../components/layout";

const GiftPage = () => {
    return (
        <Layout>
        </Layout>
    );
};

export default GiftPage;
